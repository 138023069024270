@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.roleCountryItem {
  position: relative;
}

.minusButton {
  position: absolute;
  left: calc(100% + 15px);
  top: 15px;
  cursor: pointer;
}

.addButton {
  font-size: 15px;
  font-weight: bold;

  svg {
    position: relative;
    top: -2px;
  }
}

:global {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    .ant-select-item-option-state {
      color: $--color-yellow;
    }
  }
}