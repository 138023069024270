@import '../variables';
@import '../mixins';

$--font-size-11: 11px;
$--font-size-13: 13px;

@mixin custom-input() {
  color: $--color-black-grey;
  @include font-weight(medium);
  font-size: 1.3rem;
  border-width: 2px;
  border-style: solid;
  border-color: $--color-dark-grey;
  padding: 10px 16px !important;
  &::placeholder {
    color: $--color-dark-grey;
    font-size: $--font-size-13;
    @include font-weight(medium);
  }
  &:active,
  &:focus,
  &:hover {
    border-color: $--color-black-grey;
    outline: none;
    box-shadow: none;
  }
  &-disabled {
    color: #b3b3b3;
    background-color: $--color-light-grey;
    border-color: transparent;
    @include font-weight(medium);
  }
}

@mixin custom-password-input() {
  color: $--color-black-grey;
  @include font-weight(medium);
  font-size: $--font-size-13 !important;
  border: 2px solid $--color-dark-grey;

  .ant-input {
    margin-bottom: 0;
    padding: 9px 61px 9px 3px !important;
    &:active,
    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
    }
  }

  &.ant-input-affix-wrapper-focused {
    border: 2px solid $--color-black-grey !important;
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: $--color-dark-grey;
    font-size: $--font-size-13;
    @include font-weight(medium);
  }
  &:active,
  &:focus,
  &:hover {
    border: 2px solid $--color-black-grey !important;
    outline: none;
    box-shadow: none;
  }
  &-disabled {
    color: #b3b3b3;
    background-color: $--color-light-grey;
    border: none;
    @include font-weight(medium);
    &:hover {
      border: 2px solid $--color-light-grey !important;
    }
  }
}

@mixin custom-input-number() {
  color: $--color-black-grey;
  @include font-weight(medium);
  font-size: $--font-size-13 !important;
  border: 2px solid $--color-dark-grey;
  padding: 2px 5px 2px 10px !important;
  margin-bottom: 10px;
  &::placeholder {
    color: $--color-dark-grey;
    font-size: $--font-size-13;
    @include font-weight(medium);
  }
  &:active,
  &:focus,
  &:hover {
    border: 2px solid $--color-black-grey !important;
    outline: none;
    box-shadow: none;
  }
  &-disabled {
    color: $--color-black-grey;
    background-color: $--color-light-grey;
    border: none;
    @include font-weight(medium);
  }
}
